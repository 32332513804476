const backendUrl =
  process?.env?.NODE_ENV === "production" ? "" : "http://localhost:3098";

const Constants = {
  CardsArrTemplate: [
    { feature: "nonIntrusiveAds", usingFeature: false },
    { feature: "headerBidding", usingFeature: false },
    { feature: "numberOfDemandPartners", count: 0 },
    { feature: "amazonIntegration", usingFeature: false },
    { feature: "country_1", name: "" },
    { feature: "country_2", name: "" },
    { feature: "country_3", name: "" },
    { feature: "country_4", name: "" },
    { feature: "country_5", name: "" },
    { feature: "penetration_country1", value: 0 },
    { feature: "penetration_country2", value: 0 },
  ],
  urls: {
    compDetailUrl: backendUrl + "/api/getCompnayDetails",
    ampTagUrl: backendUrl + "/api/getAdrecoverAmpData",
    genieeAMPTagUrl: backendUrl + "/api/getGenieeAmpTag",
    screenshotUrl: backendUrl + "/api/screenshot",
    genieeScreenshotUrl: backendUrl + "/api/genieescreenshot",
    getCompetitors: backendUrl + "/api/getCompetitors",
  },
  links: {
    requestDemoLink:
      "https://www.adpushup.com/publisher/adblock-recovery/?utm_campaign=outbound&utm_source=adrecoverIndex&utm_medium=email",
    requestDemoCalenderLink:
      "https://meetings.hubspot.com/tanisha-verma/meet-with-tanisha",
  },
  axiosConfig: {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  },
};

export default Constants;
