import Constants from './constants';

const setPropDataFromTags = (tagData) => {
  let tempCards = Constants.CardsArrTemplate.map((item, index) => {
    if (item.feature === 'nonIntrusiveAds') item.usingFeature = tagData['Presence of Non-Intrusive Ads'];
    else if (item.feature === 'headerBidding') item.usingFeature = tagData['Header bidding on AdBlock Inventory'];
    else if (item.feature === 'numberOfDemandPartners') item.count = tagData['Number of demand partners'];
    else if (item.feature === 'amazonIntegration') item.usingFeature = tagData['Amazon Integration'];
    else if (item.feature === 'country_1') item.name = tagData['Country Name 1'];
    else if (item.feature === 'country_2') item.name = tagData['Country Name 2'];
    else if (item.feature === 'country_3') item.name = tagData['Country Name 3'];
    else if (item.feature === 'country_4') item.name = tagData['Country Name 4'];
    else if (item.feature === 'country_5') item.name = tagData['Country Name 5'];
    else if (item.feature === 'penetration_country1') item.value = tagData['Penetration Country 1'];
    else if (item.feature === 'penetration_country2') item.value = tagData['Penetration Country 2'];
    return item;
  });

  console.log(tempCards);
  return tempCards;
  // callback(tempCards);
};

export { setPropDataFromTags };
