function Introduction({ tagData }) {
  return (
    <div className='bg-adpushupRed h-full overflow-hidden  block md:hidden'>
      <main className=' w-10/12 lg:w-9/12 py-16 mx-auto flex gap-x-10 items-center justify-center'>
        <div className='text-justify sm:px-1'>
          <h1 className='tracking-tight font-extrabold sm:font-bold text-white mb-10 text-5xl sm:text-6xl lg:text-7xl'>
            <span className='block xl:inline'>Introduction</span>
          </h1>
          <p className='mt-3 text-base text-white sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-2rem md:mt-5 md:text-lg 2xl:text-xl lg:mx-0 sm:px-1rem'>
          Ad blockers cost web publishers $16 billion annually. But are they taking action? Are you taking action?
          <br/>
          To answer these questions, we conducted a thorough and objective analysis of your website's adtech setup from an adblock recovery perspective. We have curated a detailed report on our findings, along with recommendations and actionables to help you stay ahead of your competition.
          <br/>
          Based on your current setup, we have given you the following score:
          </p>
          <br/>
          <div className='text-center'>
            <p className='text-white text-2xl font-bold'>Adblock Traffic Monetisation Audit score: <span className='text-4xl font-extrabold underline'>{(tagData['Final Score'] === 0) ? "Very low (" +  tagData['Final Score']+"/100)": tagData['Final Score']+"/100"}</span></p>
          </div>
          <br />
        </div>

        {/* <div className="lg:w-1/2 hidden lg:block mt-20">
          <img src={HeroImage} alt="Audit" />
        </div> */}
      </main>
    </div>
  );
}

export default Introduction;
