import "./styles/App.css";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useState } from "react";

import { Navbar, Footer, ScrollButton } from "./components";
import AuditScreen from "./screens/AuditScreen";
import CompetitionAnalysis from "./screens/CompetitionAnalysis/index";
import NotFound from "./screens/404";
import ReactGA from "react-ga";
import GenieeAuditScreen from "./screens/GenieeAuditScreen";
import GenieeFooter from "./components/GenieeFooter";
import AdrecoverAuditScreen from "./adrecoverscreens/AdrecoverAuditScreen";

function App() {
  ReactGA.initialize("UA-40677919-2");
  ReactGA.pageview(window.location.pathname + window.location.search);
  const [isGeniee, setIsGeniee] = useState(false);

  return (
    <Router>
      {/* <Navbar /> */}
      <Switch>
        {/* <Route path="/compAnalysis/:companyID" component={CompetitionAnalysis} /> */}
        <Route path="/:companyID" component={AuditScreen} exact />

        <Route
          path="/geniee/:sitename"
          render={() => {
            setIsGeniee(true);
            return <GenieeAuditScreen />;
          }}
        />
         <Route path="/adrecover/index/:companyID" component={AdrecoverAuditScreen} exact />
        {/* <Route path="/*" component={NotFound} /> */}
      </Switch>
      <ScrollButton />
      {isGeniee ? <GenieeFooter /> : <Footer />}
      {/* <Footer /> */}
    </Router>
  );
}

export default App;
