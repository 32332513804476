import { Chart } from "react-google-charts";
import { PieChart, Pie, Cell, Tooltip, Legend } from "recharts";
import '../../styles/HeroSection.css';
import useWindowSize from '../../adrecovercore/hooks/useWindowSize';

function HeroSection({ compName, tagData }) {
  const { width: innerWidth } = useWindowSize();

  const COLORS = ["#c43341", "#ffd9dd"];
  const score = tagData['Final Score'] === 0 ? "Very low (" +  Math.round(tagData['Final Score'])+"/100)": Math.round(tagData['Final Score']);
  const pieData = [
    {
      name: "Your Score",
      value: score
   },
    {
       name: "Total",
       value: 75
    },
 ];
 const COLORSForMarketScore = ["#c43341", "#ffd9dd"];
 const pieDataForMarketScore = [
  {
    name: "Market Average",
    value: 55
 },
  {
     name: "Total",
     value: 45
  },
];
 const CustomTooltip = ({ active, payload, label }) => {
  if (active) {
     return (
     <div
        className="custom-tooltip"
        style={{
           backgroundColor: "#2F4F4F",
           padding: "5px",
           border: "1px solid #cccc"
        }}
     >
        <label>{`${payload[0].name} : ${payload[0].value}%`}</label>
     </div>
  );
}
return null;
}
  const isMobile = innerWidth < 560;

  return (
    <>
      <div className='lg:min-h-screen md:min-h-screen bg-white overflow-hidden flex flex-col justify-between'>
      <div className='color-rect sunsetOrange'></div>
      <div className='color-rect cornflowerBlue'></div>
      <div className={((window.innerWidth > 1700)? '' :'color-rect sunglow')}></div>
      <div className={((isMobile || (window.innerWidth > 1440))? '' :'color-rect tuna')}></div>

      <div className='sm:max-w-4xl md:max-w-full lg:max-w-4xl pb-10 ml-5 md:ml-0 lg:ml-20 md:w-full lg:w-auto '>
        <div className='pb-16 md:pb-20 lg:pb-28 xl:pb-32'>
          <main className='mx-auto px-10 sm:px-6 lg:px-15' style={{ marginTop: innerWidth >= 768 && innerWidth <= 1200 ? '7rem' : '3rem' }}>
            <div className='sm:text-center lg:text-left pt-10'>
              <h1 className='text-tuna lg:text-6xl md:text-6px sm:text-4px lg:text-8xl font-extrabold px-15'>
                <p className='poppins-font uppercase text-3xl md:text-7xl lg:text-7xl mt-5 ml-0 lg:ml-2 px-15 text-adpushupRed'>{compName}</p>
                <p
                  className='block uppercase'
                  style={{
                    fontSize: innerWidth >= 1200 ? '6rem' : innerWidth >= 768 ? '5rem' : '2rem',
                    lineHeight: isMobile ? '3rem' : innerWidth >= 768 && innerWidth <= 1200 ? '7rem' : 'inherit',
                    paddingTop: isMobile ? '1rem' : innerWidth >= 768 ? '3rem' : 0,
                  }}
                >
                  Adblock
                  <br />
                  <span className='lg:whitespace-nowrap'>Traffic Monetization</span>
                  <br/>
                  <span className='lg:whitespace'>Index 2024</span>
                </p>
                <p className='block text-adpushupRed text-3xl md:text-7xl lg:text-4xl sm:text-3xl mt-5 ml-0 lg:ml-2 md:text-center lg:whitespace-nowrap'>An Exclusive 360° Adblock Traffic Monetisation Audit just for you</p>
              </h1>
            </div>
          </main>
        </div>
      </div>
      <main className='hidden lg:flex w-12/12 lg:w-12/12 py-20 gap-x-10 p-20 text-center justify-center bg-adpushupRed'>
        <div className='justify-center'>
          <h1 className='tracking-tight font-extrabold text-white mb-10 text-6xl 2xl:text-7xl'>
            <span className='block xl:inline'>Introduction</span>
          </h1>
           <p className='mt-3 w-full text-base text-white  2xl:text-xl lg:mx-0'>Ad blockers cost web publishers $16 billion annually. But are they taking action? Are you taking action?</p>
          <p className='mt-3 w-full text-base text-white  2xl:text-xl lg:mx-0'>
          To answer these questions, we conducted a thorough and objective analysis of your website's adtech setup from an adblock recovery perspective. 
          
          <br/>
          <br/>We have curated a detailed report on our findings, along with recommendations and actionables to help you stay ahead of your competition. Based on your current setup, we have given you the following score:
          </p>
          <br/>
          <div className='text-center'>
            <p className='text-white text-2xl lg:font-bold'>Adblock Traffic Monetisation Audit score: 
            <br/><span className='text-4xl font-extrabold underline'>{(tagData['Final Score'] === 0) ? "Very low (" +  Math.round(tagData['Final Score'])+"/100)": Math.round(tagData['Final Score'])+"/100"}</span></p>
          </div>
          <br />
        </div>
        <div className='justify-center'>
          <h6 className='tracking-tight font-bold text-white mb-10 text-6xl 2xl:text-7xl'>
            <span className='block xl:inline'>You vs Market Average</span>
          </h6>
          <span className="margin-left text-white">Your Score{'     '}</span>
           <span className="margin-right text-white"><span></span>Market Average</span>
           <p className='mt-2 w-full text-base text-white  2xl:text-xl lg:mx-0'> 
            <div className='chart-parent'>
       <PieChart width={300} height={290}>
      <Pie
         data={pieData}
         color="#000000"
         dataKey="value"
         nameKey="name"
         cx="50%"
         cy="50%"
         outerRadius={120}
         fill="#8884d8"
      >
         {pieData.map((entry, index) => (
            <Cell
               key={`cell-${index}`}
               fill={COLORS[index % COLORS.length]}
            />
         ))}
      </Pie>
      <Tooltip content={<CustomTooltip />} />
      <Legend />
      </PieChart>
      <PieChart width={300} height={290}>
      <Pie
         data={pieDataForMarketScore}
         color="#000000"
         dataKey="value"
         nameKey="name"
         cx="50%"
         cy="50%"
         outerRadius={120}
         fill="#8884d8"
      >
         {pieData.map((entry, index) => (
            <Cell
               key={`cell-${index}`}
               fill={COLORSForMarketScore[index % COLORSForMarketScore.length]}
            />
         ))}
      </Pie>
      <Tooltip content={<CustomTooltip />} />
      <Legend />
      </PieChart>
          </div>
           </p>
          <br/>
          <br />
        </div>
      </main>
    </div>
    </>
  );
}

export default HeroSection;
