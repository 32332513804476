import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import useWindowSize from '../adrecovercore/hooks/useWindowSize';
function FeatureTable({ tagData }) {
  const { width: innerWidth } = useWindowSize();

  const isMobile = innerWidth < 560;
  var compatibilityWithAdblockRecoverySolutionScore = (tagData['Penetration Country 1'] > tagData['Penetration Country 2'] ? (tagData['Penetration Country 1'] * 100) : (tagData['Penetration Country 2'] * 100))
  return (
    <div className='mx-auto rounded-md my-10 sm:w-full lg:w-5/12 bg-white text-tuna'>
      <table className='table-auto border-collapse px-1 '>
        <thead className='text-xl lg:text-2xl'>
          <tr>
            <th className='py-3' style={{
                    textAlign: isMobile ? 'left' : '',
                    paddingLeft: isMobile ? '1.5rem' : ''
                  }}>Criteria</th>
            <th className='py-3'>Assessment</th>
          </tr>
        </thead>

        <tbody className='text-lg'>
          <tr>
            <td className='w-1/2 py-1 pl-1' style={{
                    textAlign: isMobile ? 'left' : '',
                    paddingLeft: isMobile ? '1.2rem' : ''
                  }}>Presence of Non-Intrusive Ads
                  </td>
            <td className='w-1/3 py-1'>{(tagData['Presence of Non-Intrusive Ads'] === 'true') ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faTimes} />}</td>
          </tr>
          <tr>
            <td className='w-1/2 py-1 pl-1' style={{
                    textAlign: isMobile ? 'left' : '',
                    paddingLeft: isMobile ? '1.2rem' : ''
                  }}>Number of Demand Partners</td>
            <td className='w-1/3 py-1'>
              <b>{(tagData['Presence of Non-Intrusive Ads'] === 'true') ? tagData['Number of demand partners'] : 0}</b>
            </td>
          </tr>
          <tr>
            <td className='w-1/2 py-1 pl-1' style={{
                    textAlign: isMobile ? 'left' : '',
                    paddingLeft: isMobile ? '1.2rem' : ''
                  }}>Header bidding on AdBlock Inventory</td>
            <td>{(tagData['Header bidding on AdBlock Inventory'] === 'true' && (tagData['Presence of Non-Intrusive Ads'] === 'true')) ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faTimes} />}</td>
          </tr>
          
          <tr>
            <td className='w-1/2 py-1 pl-1' style={{
                    textAlign: isMobile ? 'left' : '',
                    paddingLeft: isMobile ? '1.2rem' : ''
                  }}>Amazon Integration</td>
            <td>{(tagData['Amazon Integration'] === 'true' && (tagData['Presence of Non-Intrusive Ads'] === 'true')) ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faTimes} />}</td>
          </tr>

          <tr>
            <td className='w-1/2 py-1 pl-1' style={{
                    textAlign: isMobile ? 'left' : '',
                    paddingLeft: isMobile ? '1.2rem' : ''
                  }}>Compatibility with AdBlock recovery solution</td>
            <td>{<FontAwesomeIcon icon={faCheck} />}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default FeatureTable;
