import { AdblockRecoverCompatibility, Finding } from "./index";
import AnalyticsSvg from "../assets/analytics.svg";
import BidCachingSvg from "../assets/bidcaching.svg";
import NumberOfDemandPartnersSvg from "../assets/numberofdemandpartners.svg";
import AmazonIntegrationSvg from "../assets/amazonintegration.svg";
import CompatibilitySvg from "../assets/compatibility.svg"

function Findings({ propertiesArr, compName }) {

  var compatibilityWithAdblockRecoveryRenderCount = 0;
  var country_1Name = '';
  var country_2Name = '';
  var country_3Name = '';
  var country_4Name = '';
  var country_5Name = '';
  var penetration_country1 = 0;
  var penetration_country2 = 0;

  propertiesArr.map((item)=>{
    if(item.feature === 'country_1'){
      country_1Name = item.name;
    }else if(item.feature === 'country_2'){
      country_2Name = item.name;
    }else if(item.feature === 'country_3'){
      country_3Name = item.name;
    }else if(item.feature === 'country_4'){
      country_4Name = item.name;
    }else if(item.feature === 'country_5'){
      country_5Name = item.name;
    }else if(item.feature === 'penetration_country1'){
      penetration_country1 = item.value;
    }else if(item.feature === 'penetration_country2'){
      penetration_country2 = item.value;
    }
  })

  var compatibilityWithAdblockRecoverySolutionFindingPara = (country_2Name !== '#N/A' ? (country_4Name !== "#N/A" ? ("The majority of traffic on your website is from " + country_1Name + ", followed by " + country_2Name+  " and " + country_3Name+ ". We have also found some traffic coming from " + country_4Name) : ("The majority of traffic on your website is from" + country_1Name + ", followed by" + country_2Name)) : ("The majority of traffic on your website is from " + country_1Name))
  var compatibilityWithAdblockRecoverySolutionFindingPara2 = (country_2Name !== '#N/A') ? ((penetration_country1 > penetration_country2 ? country_1Name: country_2Name) + ", your top traffic source, has an AdBlock penetration rate of " + (penetration_country1 > penetration_country2 ? penetration_country1*100 : penetration_country2*100) + "%") : ((country_1Name) + ", your top traffic source, has an AdBlock penetration rate of " + ( penetration_country1*100) + "%")
  var compatibilityWithAdblockRecoverySolutionFindingPara3 = (country_2Name !== '#N/A') ? "Based on our findings, you might be losing approximately " + (penetration_country1 > penetration_country2 ? (penetration_country1 * 100 - 5) : (penetration_country2 * 100 - 5)) + "% of your revenue to adblockers." : "Based on our findings, you might be losing approximately " + ((penetration_country1 * 100 - 5)) + "% of your revenue to adblockers."

  var compatibilityWithAdblockRecoverySolution = {
    title: "Compatibility with AdBlock recovery solution",
    enabled: {
      findingsArr: [
        compatibilityWithAdblockRecoverySolutionFindingPara,
        compatibilityWithAdblockRecoverySolutionFindingPara2,
        compatibilityWithAdblockRecoverySolutionFindingPara3
      ],
      recommendArr: [
        "AdRecover recommends using an AdBlock recovery solution to monetise your AdBlock traffic by showing non-intrusive ads to them, and thus recover a significant chunk of the lost revenue.",
      ],
    },
    notEnabled: {
      findingsArr: [
        compatibilityWithAdblockRecoverySolutionFindingPara,
        compatibilityWithAdblockRecoverySolutionFindingPara2,
        compatibilityWithAdblockRecoverySolutionFindingPara3
      ],
      recommendArr: [
        "AdRecover recommends using an AdBlock recovery solution to monetise your AdBlock traffic by showing non-intrusive ads to them, and thus recover a significant chunk of the lost revenue.",
      ],
    },
    image: CompatibilitySvg,
  }
  
  const data = {
    nonIntrusiveAds: {
      title: "Presence of Non-Intrusive Ads",
      enabled: {
        findingsArr: [
          "An AdBlock revenue recovery setup has been implemented on your website to allow Non-Intrusive Ads",
          "No AdBlock revenue recovery solution is being used for Samsung and Opera mobile browsers"
        ],
        recommendArr: [
          '✅ AdRecover applauds the implementation of an AdBlock revenue recovery setup on your website that helps you recover a large part of the lost revenue',
          `AdRecover also strongly recommends that a mobile AdBlock revenue recovery solution should be implemented for recovering the revenue lost on Samsung and Opera browsers`,
          'Web publishers worldwide are losing anywhere from 10-40% of their ad revenue (depending on traffic geo, ad density, industry vertical, and other factors) due to the increasing use of AdBlock.'
        ],
      },
      notEnabled: {
        findingsArr: [
          "No AdBlock revenue recovery setup has been implemented on your website to allow Non-Intrusive Ads",
          "No AdBlock revenue recovery solution is being used for Samsung and Opera mobile browsers"
        ],
        recommendArr: [
          "AdRecover recommends the use of AdRecover as AdRecover helps you show non-intrusive ads to your AdBlock traffic through header bidding, and hence, recovers a majority of the lost revenue",
          `AdRecover strongly recommends that a mobile AdBlock revenue recovery solution should be implemented for recovering the revenue lost on Samsung and Opera browsers`,
          "Web publishers worldwide are losing anywhere from 10-40% of their ad revenue (depending on traffic geo, ad density, industry vertical, and other factors) due to the increasing use of AdBlock."
        ],
      },
      image: AnalyticsSvg,
    },
    numberOfDemandPartners: {
      title: "Number of demand partners",
      lessThan7: {
        findingsArr: [
          "The total number of demand partners running on your adblocker inventory was found to be less than 7",
        ],
        recommendArr: [
          "Basis the analysis of your demand partners,website category, and traffic share - AdRecover recommends that the total number of demand partners running on your AdBlock Inventory should be 7",
        ],
      },
      moreThan7: {
        findingsArr: [
          "The total number of demand partners running on your adblocker inventory was found to be more than 7",
        ],
        recommendArr: [
          "Basis the analysis of your demand partners,website category, and traffic share - AdRecover recommends that the total number of demand partners running on your AdBlock Inventory should be 7",
        ],
      },
      equalTo7: {
        findingsArr: [
          "The total number of demand partners running on your ad setup was found to be 7",
        ],
        recommendArr: [
          "✅ AdRecover applauds that the total number of demand partners running on your AdBlock Inventory is 7 which we believe is a balanced number for your website considering its category and traffic share",
          "However, you can choose to either increase or decrease this number as per your business requirements",
        ],
      },
      image: NumberOfDemandPartnersSvg,
    },
    headerBidding: {
      title: "Header bidding on AdBlock Inventory",
      enabled: {
        findingsArr: ["Header bidding has been implemented to show ads on your AdBlock Inventory"],
        recommendArr: [
          "✅ AdRecover applauds the implementation of header bidding on your Adblock Inventory",
          "Header bidding on Adblock Inventory offers publishers a way to simultaneously offer their ad space to numerous SSPs or Ad Exchanges at once for the users using an adblocker. This, in turn, significantly boosts your overall ad revenue yield",
        ],
      },
      notEnabled: {
        findingsArr: [
          "Header bidding could not be found on your AdBlock Inventory",
        ],
        recommendArr: [
          "AdPushup strongly recommends implementing header bidding on your adblock inventory to increase the demand for your ad inventory.",
          "Header bidding on Adblock Inventory offers publishers a way to simultaneously offer their ad space to numerous SSPs or Ad Exchanges at once for the users using an adblocker. This, in turn, significantly boosts your overall ad revenue",
        ],
      },
      image: BidCachingSvg,
    },
    amazonIntegration: {
      title: "Amazon Integration",
      enabled: {
        findingsArr: [
          "Amazon UAM/TAM demand is found to be enabled on your website's AdBlock Inventory",
        ],
        recommendArr: [
          "✅ AdRecover applauds the integration of Amazon UAM/TAM into your AdBlock Inventory",
          "It helps you access more buyers which increases bid pressure and eventually, your overall ad revenue yield",
        ],
      },
      notEnabled: {
        findingsArr: [
          "Amazon UAM/TAM demand is not enabled on your website's AdBlock Inventory",
        ],
        recommendArr: [
          "AdRecover recommends the integration with Amazon UAM/TAM so that you get more demand for your traffic",
          "It can help you access more buyers which increases bid pressure and eventually, your overall ad revenue yield",
        ],
      },
      image: AmazonIntegrationSvg,
    },
    compatibilityWithAdblockRecoverySolution: compatibilityWithAdblockRecoverySolution
  };

  const intrusiveAdsPresent =  (propertiesArr[0]?.usingFeature === 'true');

  return (
    <section className="py-4">
      <div className="grid gap-y-8 my-8 grid-cols-1 ">
        {propertiesArr.map((item, index) => {
          var feature;
          if(item.feature === 'country_1' || item.feature === 'country_2' || item.feature === 'country_3' || item.feature === 'country_4' || item.feature === 'country_5' || item.feature === 'penetration_country1' || item.feature === 'penetration_country2'){
            feature = data.compatibilityWithAdblockRecoverySolution;
            compatibilityWithAdblockRecoveryRenderCount = compatibilityWithAdblockRecoveryRenderCount + 1;
          }else{
            feature = data[item.feature];
          }
          if (item.feature === "numberOfDemandPartners") {
            return (
              <Finding
                key={"finding" + feature.title + index}
                title={feature.title}
                findings={
                  item.count === 5
                    ? feature.equalTo7.findingsArr
                    : item.count < 7
                    ? feature.lessThan7.findingsArr
                    : feature.moreThan7.findingsArr
                }
                recommendations={
                  item.count === 5
                    ? feature.equalTo7.recommendArr
                    : item.count < 7
                    ? feature.lessThan7.recommendArr
                    : feature.moreThan7.recommendArr
                }
                image={feature.image}
                onLeft={index % 2 === 0}
              />
            );
          }
          if((item.feature === 'country_1' || item.feature === 'country_2' || item.feature === 'country_3' || item.feature === 'country_4' || item.feature === 'country_5' || item.feature === 'penetration_country1' || item.feature === 'penetration_country2') && compatibilityWithAdblockRecoveryRenderCount > 1){
            return;
          }
          return (
            <Finding
              key={"finding" + item.feature.title + index}
              title={feature.title}
              findings={
                item.feature === 'compatibilityWithAdblockRecoverySolution' ? feature.enabled.findingsArr : ( (item?.usingFeature === "true" && intrusiveAdsPresent)
                ? feature.enabled.findingsArr
                : feature.notEnabled.findingsArr)
              }
              recommendations={
                item.feature === 'compatibilityWithAdblockRecoverySolution' ? feature.enabled.recommendArr : (  (item?.usingFeature === "true" && intrusiveAdsPresent)
                ? feature.enabled.recommendArr
                : feature.notEnabled.recommendArr)
              }
              image={feature.image}
              onLeft={index % 2 === 0}
            />
          );
        })}
      </div>
    </section>
  );
}

export default Findings;
