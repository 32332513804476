import "../../styles/HeroSection.css";
import { Link } from "react-scroll";
import { useState, useEffect } from "react";
import HeroImage from "../../assets/Intro.svg";
import useWindowSize from "../../core/hooks/useWindowSize";

function HeroSection({ compName, tagData }) {
  const { width: innerWidth } = useWindowSize();

  const isMobile = innerWidth < 560;
  const [companyName, setCompanyName] = useState("");
 
  useEffect(() => {
    var a = window.location.href.split("/");
    setCompanyName(a[a.length - 1]);
  }, []);

  return (
    <div className="lg:min-h-screen md:min-h-screen bg-white overflow-hidden flex flex-col justify-between">
      <div className="color-rect sunsetOrange"></div>
      <div className="color-rect cornflowerBlue"></div>
      <div className={((window.innerWidth > 1700)? '' :'color-rect sunglow')}></div>
      <div className={((isMobile || (window.innerWidth > 1440))? '' :'color-rect tuna')}></div>

      <div className="sm:max-w-4xl md:max-w-full lg:max-w-4xl pb-10 ml-5 md:ml-0 lg:ml-20 md:w-full lg:w-auto ">
        <div className="pb-16 md:pb-20 lg:pb-28 xl:pb-32">
          <main
            className="mx-auto px-10 sm:px-6 lg:px-15"
            style={{
              marginTop:
                innerWidth >= 768 && innerWidth <= 1200 ? "7rem" : "3rem",
            }}
          >
            <div className="sm:text-center lg:text-left pt-10">
              <h1 className="text-tuna lg:text-6xl md:text-6px sm:text-4px lg:text-8xl font-extrabold px-15">
                <p className="poppins-font uppercase text-3xl md:text-7xl lg:text-7xl mt-5 ml-0 lg:ml-2 px-15 text-genieeBlue">
                  {companyName}
                </p>
                <p
                  className="block uppercase"
                  style={{
                    fontSize:
                      innerWidth >= 1200
                        ? "6rem"
                        : innerWidth >= 768
                        ? "5rem"
                        : "2rem",
                    lineHeight: isMobile
                      ? "3rem"
                      : innerWidth >= 768 && innerWidth <= 1200
                      ? "7rem"
                      : "inherit",
                    paddingTop: isMobile
                      ? "1rem"
                      : innerWidth >= 768
                      ? "3rem"
                      : 0,
                  }}
                >
                  Programmatic
                  <br />
                  <span className="lg:whitespace-nowrap">Yield Index 2024</span>
                </p>
                <p className="block text-genieeBlue text-3xl md:text-7xl lg:text-4xl sm:text-3xl mt-5 ml-0 lg:ml-2 md:text-center lg:whitespace-nowrap">
                  An Exclusive 360° Ad Revenue Audit just for you
                </p>
              </h1>
            </div>
          </main>
        </div>
      </div>
      <main className="hidden lg:flex w-12/12 lg:w-12/12 py-20 gap-x-10 p-20 text-center justify-center bg-genieeBlue">
        <div className="justify-center">
          <h1 className="tracking-tight font-extrabold text-white mb-10 text-6xl 2xl:text-7xl">
            <span className="block xl:inline">Introduction</span>
          </h1>
          <p className="mt-3 w-full text-base text-white  2xl:text-xl lg:mx-0 text-justify">
            Offering a good user experience while optimizing your ad revenues is
            a difficult feat to achieve. In that spirit, as a part of our
            Programmatic Yield Index program, we conducted a thorough and
            objective analysis of your programmatic setup and found that there
            are certain key optimization areas.
          </p>
          <br />
          <div className="text-center">
            <p className="text-white text-2xl lg:font-bold">
              Your Programmatic 360° Ad Audit Score:{" "}
              <span className="text-4xl font-extrabold underline">
                {tagData["Final Score"]}/100
              </span>
            </p>
          </div>
          <br />
        </div>
      </main>
    </div>
  );
}

export default HeroSection;
